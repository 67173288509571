import React from 'react';
import {Typography, Grid} from '@mui/material';
import styled from '@emotion/styled';
import SplashSwoosh from './SplashSwoosh';
import logo from '../../assets/images/griffith-logo.png';
import hero from '../../assets/images/splash-hero-right.png';

export default function SplashHero() {
  const Container = styled.div(props => ({
    background: '#fafafa'
  }))

  let GLOBAL_SPLASH_LINK = "https://flavoriq.miron.services";
  if (window.location.hostname==="conceptlocker.flavoriq.global") GLOBAL_SPLASH_LINK = "https://flavoriq.global";
  
  return (
    <main>
      <Container maxWidth="xl" disableGutters>
        <Grid container>
          <Grid item xs={1}>&nbsp;
          </Grid>
          <Grid item xs={6}>
            <div className="hero">
              <a href={GLOBAL_SPLASH_LINK}><img src={logo} alt="Griffith Foods" className='hero--logo'/></a>
              <Typography
                variant="h1"
                className="hero--headline"
                sx={{mt: 5, mb: 1}}
              >
                <em>
                  Turning Insights into
                  <br/>
                  Culinary Inspiration
                </em>
              </Typography>
              <SplashSwoosh />
              <br/>
              <Typography
                variant="body2"
                sx={{mt: 3}}
                className="hero--desc"
              >
                Show us how you orchestrate the world’s finest ingredients into culinary
                <br/>
                inspiration, based on real-life insights from kitchens around the world.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <img src={hero} alt="hero" className="hero--right"/>
          </Grid>
        </Grid>


      </Container>
    </main>
  )
}
