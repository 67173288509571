import React, {useEffect, useMemo, useState} from 'react'
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import dayjs from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import uploadFileToBlob from '../upload/AzureBlobStorage'
import {format} from 'date-fns'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {useDispatch, useSelector} from 'react-redux'
import {unwrapResult} from '@reduxjs/toolkit'
import {createConcept} from '../../../store/conceptsSlice'
import {useNavigate} from 'react-router-dom';
import {getUser} from '../../../store/userSlice'
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import HealthChoices from "./HealthOptionsModal";
import Fuse from "fuse.js";


export default function CreateConcept() {
  const fallbackSrc = "/images/default-ppt.png";
  const [chefList, setChefList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [mealpartList, setMealPartList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [healthOptList, setHealthOptList] = useState([]);
  // const [productInnoList, setProductInnoList] = useState([]);
  const [productCatList, setProductCatList] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [image, setImage] = useState({preview: fallbackSrc, raw: ""});
  const [savingConcept, setSavingConcept] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const [visibleProducts, setVisibleProducts] = useState(1);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [filteredBrands, setFilteredBrands] = useState(brandList); // State for filtered brands

  const OTHER_BRAND_OPTION = {id: '999', brandName: 'Other Brand'};
  const brandListWithOther = useMemo(() => {
    // Sort brandList alphabetically and place OTHER_BRAND_OPTION at the top
    const sortedBrands = [...brandList].sort((a, b) => a.brandName.localeCompare(b.brandName));
    return [OTHER_BRAND_OPTION, ...sortedBrands];
  }, [brandList]);

  const fuse = useMemo(() => {
    return new Fuse(brandListWithOther, {
      keys: ['brandName'],
      includeScore: true,
      shouldSort: true,
      threshold: 0.3,
      distance: 300,
    });
  }, [brandListWithOther]);

  useEffect(() => {
    setFilteredBrands(brandListWithOther);
  }, [brandListWithOther]);

  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const fetchBrandsBySegmentId = async (segmentId) => {
    try {
      let response = await fetch(`api/brands/segment/${segmentId}`);
      if (response.status === 200) {
        let data = await response.json();
        setBrandList(data);
      } else {
        throw `Error fetching brand list for segment id ${segmentId}`;
      }
    } catch (error) {
      setIsError(true)
    }
  }

  useEffect(() => {

    const fetchChefs = async () => {
      try {
        let response = await fetch('api/chefs');
        if (response.status === 200) {
          let data = await response.json();
          setChefList(data);
        } else {
          throw 'Error fetching chefs list'
        }
      } catch (error) {
        setIsError(true)
      }
    }
    fetchChefs();

    const fetchMealParts = async () => {
      try {
        let response = await fetch('api/mealparts');
        if (response.status === 200) {
          let data = await response.json();
          setMealPartList(data);
        } else {
          throw 'Error fetching meal part list'
        }
      } catch (error) {
        setIsError(true)
      }
    }
    fetchMealParts();

    const fetchRegions = async () => {
      try {
        let response = await fetch('api/countries');
        if (response.status === 200) {
          let data = await response.json();
          setRegionList(data.sort((a, b) => a.countryLongName.localeCompare(b.countryLongName)))
        } else {
          throw 'Error fetching region list'
        }
      } catch (error) {
        setIsError(true)
      }
    }
    fetchRegions();

    const fetchHealthOptions = async () => {
      try {
        let response = await fetch('api/healthoptions');
        if (response.status === 200) {
          let data = await response.json();
          setHealthOptList(data);
        } else {
          throw 'Error fetching health options'
        }
      } catch (error) {
        setIsError(true)
      }
    }
    fetchHealthOptions();

    // const fetchProductInnovations = async () => {
    //   try {
    //     let response = await fetch('api/ProductInnovations');
    //     if (response.status === 200) {
    //       let data = await response.json();
    //       setProductInnoList(data);
    //     } else {
    //       throw 'Error fetching product innovations'
    //     }
    //   } catch (error) {
    //     setIsError(true)
    //   }
    // }
    // fetchProductInnovations();

    const fetchFeaturedProductCats = async () => {
      try {
        let response = await fetch('api/productcategories');
        if (response.status === 200) {
          let data = await response.json();
          setProductCatList(data);
        } else {
          throw 'Error fetching product cat list'
        }
      } catch (error) {
        setIsError(true)
      }
    }
    fetchFeaturedProductCats();
    const fetchSegmentList = async () => {
      try {
        let response = await fetch('api/segments');
        if (response.status === 200) {
          let data = await response.json();
          setSegmentList(data);
        } else {
          throw 'Error fetching segment list'
        }
      } catch (error) {
        setIsError(true)
      }
    }
    fetchSegmentList();

  }, [])


  const navigate = useNavigate();
  const FILE_SIZE_MAX = 5000000
  const PRODUCT_DESCRIPTION_MAX_LENGTH = 140;
  const PRODUCT_CODE_MAX_LENGTH = 50;

  const formik = useFormik({
    initialValues: {
      conceptName: '',
      mealPart: '',
      description: '',
      creationDate: dayjs(new Date().toISOString().split('T')[0]),
      chef: '',
      brand: '',
      segment: '',
      regionalInsight: '',
      regionCountry: '',
      brandOtherText: '',
      healthOptions: [],
      productCat1: '',
      productCat1Description: '',
      // productCat1Innovation: null,
      productCat1Code: '',
      productCat2: '',
      productCat2Description: '',
      // productCat2Innovation: null,
      productCat2Code: '',
      productCat3: '',
      productCat3Description: '',
      // productCat3Innovation: null,
      productCat3Code: '',
      productCat4: '',
      productCat4Description: '',
      // productCat4Innovation: null,
      productCat4Code: '',
      photo: null,
    },
    validationSchema: Yup.object({
      conceptName: Yup.string()
        .required('Concept name is required'),
      mealPart: Yup.string()
        .required('Meal part is required'),
      description: Yup.string()
        .required('Description is required'),
      chef: Yup.string()
        .required('Chef is required'),
      regionCountry: Yup.string()
        .required('Country or region is required'),
      segment: Yup.string()
        .required('Segment is required'),
      brand: Yup.string()
        .required('Brand is required'),
      brandOtherText: Yup.string().ensure().when('brand', {
        is: OTHER_BRAND_OPTION.id,
        then: Yup.string().required('Brand is required')
      }),
      productCat1: Yup.number()
        .required('At least one product category is required'),
      productCat1Description: Yup.string().when('productCat1', {
        is: (productCat1) => productCat1,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat2Description: Yup.string().when('productCat2', {
        is: (productCat2) => productCat2,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat3Description: Yup.string().when('productCat3', {
        is: (productCat3) => productCat3,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat4Description: Yup.string().when('productCat4', {
        is: (productCat4) => productCat4,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      // photo: Yup.mixed()
      //   .nullable(true)
      //   .notRequired()
      //   .test("FILE_SIZE_MAX", "Uploaded file is too big. Maximum 5 MB.",
      //     value => !value || (value.size <= FILE_SIZE_MAX)),
    }),
    onSubmit: values => {

      //if not default image push image to azure
      if (values.photo) {
        onImageUpload(image.raw)
      }
      //submit form data
      saveConcept(values)
    },
  });

  function transFormToNumber(value) {
    return (value === 0 || value === "0") ? null : Number(value);
  }

  function saveConcept(formData) {
    if (savingConcept)
      return;
    var fileName = null

    if (formData.photo) {
      fileName = image.raw.name
    }

    const creationDt = new Date(formData.creationDate);
    // const creationDtAddDays = new Date(creationDt.setDate(creationDt.getDate() + 5)).toISOString();

    const healthOptions = healthOptList.filter(opt => formData.healthOptions.some(s => s === opt.id));
    const data = {
      ConceptName: formData.conceptName,
      MealPart: formData.mealPart,
      Brand: formData.brand,
      Chef: formData.chef,
      BrandOtherText: formData.brandOtherText,
      Segment: transFormToNumber(formData.segment),
      Description: formData.description,
      CreationDate: creationDt,
      RegionCountry: formData.regionCountry,
      RegionalInsight: formData.regionalInsight,
      BlobImageName: fileName,
      HealthOptions: healthOptions,
      ProductCat1: transFormToNumber(formData.productCat1),
      ProductCat1Description: formData.productCat1Description,
      // ProductCat1Innovation: formData.productCat1Innovation,
      ProductCat1Code: formData.productCat1Code,
      ProductCat2: transFormToNumber(formData.productCat2),
      ProductCat2Description: formData.productCat2Description,
      // ProductCat2Innovation: formData.productCat2Innovation,
      ProductCat2Code: formData.productCat2Code,
      ProductCat3: transFormToNumber(formData.productCat3),
      ProductCat3Description: formData.productCat3Description,
      // ProductCat3Innovation: formData.productCat3Innovation,
      ProductCat3Code: formData.productCat3Code,
      ProductCat4: transFormToNumber(formData.productCat4),
      ProductCat4Description: formData.productCat4Description,
      // ProductCat4Innovation: formData.productCat4Innovation,
      ProductCat4Code: formData.productCat4Code,
      RecordAddedBy: user.email,
    };
    console.log("data:::" + JSON.stringify(data))
    setSavingConcept(true);

    dispatch(createConcept(data))
      .then(unwrapResult).then(originalPromiseResult => {
      setSavingConcept(false);

      setSnackbar({children: 'Concept saved successfully', severity: 'success'});
      navigate('/admin');
    })
      .catch(rejectedValueOrSerializedError => {
        console.log(rejectedValueOrSerializedError);
        setSavingConcept(false);
        setSnackbar({children: 'Failed to save concept', severity: 'error'});
      });
  }

  const onImageUpload = async (file) => {
    // *** UPLOAD TO AZURE STORAGE ***  
    const creationDt = new Date(formik.values.creationDate);
    const creationDtAddDays = creationDt.setDate(creationDt.getDate() + 5);
    let imagePath = '/images/concepts/'
      + format(creationDtAddDays, 'yyyy') + '/'
      + format(creationDtAddDays, 'MMM').toLowerCase() + '/';

    await uploadFileToBlob(imagePath, file);
  }

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleAddProductFields = () => {
    setVisibleProducts(prev => (prev < 4 ? prev + 1 : prev));
  };

  const renderProductFields = (index) => (
    <Box key={index}>
      <Box sx={{display: 'inline-flex', width: '100%', justifyContent: 'space-between'}}>
        <Badge sx={{mt: 2, width: '78%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500}}>
          {formik.values[`productCat${index + 1}Description`]?.length || 0} / {PRODUCT_DESCRIPTION_MAX_LENGTH} characters
        </Badge>
        <Badge sx={{mt: 2, width: '20%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500}}>
          {formik.values[`productCat${index + 1}Code`]?.length || 0} / {PRODUCT_CODE_MAX_LENGTH} characters
        </Badge>
      </Box>

      <Box sx={{display: 'flex', width: '100%', gap: '15px'}}>
        <TextField
          id={`productCat${index + 1}`}
          select
          label={`Featured Category ${index + 1}` + (index === 0 ? '*' : '')}
          value={formik.values[`productCat${index + 1}`] ?? ''}
          onChange={(event) => formik.setFieldValue(`productCat${index + 1}`, event.target.value || 1)}
          sx={{width: 275}}
          variant="outlined"
        >
          {productCatList.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.categoryName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id={`productCat${index + 1}Description`}
          label="Description"
          variant="outlined"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[`productCat${index + 1}Description`] ?? ''}
          sx={{width: 350}}
          inputProps={{maxLength: PRODUCT_DESCRIPTION_MAX_LENGTH}}
        />

        {/*<TextField*/}
        {/*  id={`productCat${index + 1}Innovation`}*/}
        {/*  select*/}
        {/*  label="Innovation"*/}
        {/*  value={formik.values[`productCat${index + 1}Innovation`] ?? ''}*/}
        {/*  onChange={(event) => formik.setFieldValue(`productCat${index + 1}Innovation`, event.target.value || null)}*/}
        {/*  sx={{width: 162.8}}*/}
        {/*  variant="outlined"*/}
        {/*>*/}
        {/*  <MenuItem value="">*/}
        {/*    <em>None</em>*/}
        {/*  </MenuItem>*/}
        {/*  {productInnoList.map(option => (*/}
        {/*    <MenuItem key={option.id} value={option.id}>*/}
        {/*      {option.name}*/}
        {/*    </MenuItem>*/}
        {/*  ))}*/}
        {/*</TextField>*/}

        <TextField
          id={`productCat${index + 1}Code`}
          label="Product Code"
          variant="outlined"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[`productCat${index + 1}Code`] ?? ''}
          sx={{width: 162.8}}
          inputProps={{maxLength: PRODUCT_CODE_MAX_LENGTH}}
        />
      </Box>

      <FormHelperText className="error-message">
        {formik.touched[`productCat${index + 1}Description`] && formik.errors[`productCat${index + 1}Description`]
          ? formik.errors[`productCat${index + 1}Description`]
          : ' '}
        {index === 0 && formik.touched.productCat1 && formik.errors.productCat1 ?
          formik.errors.productCat1
          : ' '
        }
      </FormHelperText>
    </Box>
  );

  return (

    <form onSubmit={
      formik.handleSubmit}>

      <FormControl sx={{mt: 2}} fullWidth>
        <Badge sx={{textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500,}}>
          {formik.values.conceptName.length} / 150 characters
        </Badge>
        <TextField id="conceptName" label="Concept Name*" variant="outlined"
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   value={formik.values.conceptName}
                   fullWidth
                   inputProps={{
                     maxLength: 150,
                   }}
        />

      </FormControl>
      <FormHelperText className="error-message">
        {formik.touched.conceptName && formik.errors.conceptName ?
          formik.errors.conceptName
          : ' '}
      </FormHelperText>

      <TextField
        id="mealPart"
        select
        label="Meal Part*"
        value={formik.values.mealPart}
        onChange={selectedOption => {
          formik.handleChange("mealPart")(selectedOption);
        }}
        sx={{minWidth: 220, mt: 2,}}
        variant="outlined"
      >
        {mealpartList.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.mealPartName}
          </MenuItem>
        ))}
      </TextField>
      <FormHelperText className="error-message">
        {formik.touched.mealPart && formik.errors.mealPart ?
          formik.errors.mealPart
          : ' '}
      </FormHelperText>

      <FormControl fullWidth>
        <Badge sx={{textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500,}}>
          {formik.values.description.length} / 275 characters
        </Badge>
        <TextField
          id="description" label="Concept Description*" variant="outlined"
          multiline
          rows={4}
          defaultValue=""
          fullWidth
          inputProps={{
            maxLength: 275,
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </FormControl>
      <FormHelperText className="error-message">
        {formik.touched.description && formik.errors.description ?
          formik.errors.description
          : ' '}
      </FormHelperText>

      <Box sx={{display: 'block', width: '100%'}}>
        <FormControl sx={{mb: 2, mt: 4,}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year', 'month']}
              label="Creation Date*"
              openTo='month'
              minDate={dayjs('2023-01-15')}
              maxDate={dayjs('2043-12-15')}
              value={dayjs(formik.values.creationDate)}
              onChange={(value) => {
                formik.setFieldValue('creationDate', Date.parse(value));
              }}
              renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} helperText={null}
                                                  name="creationDate"/>}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>

      <TextField
        id="chef"
        select
        label="Chef*"
        value={formik.values.chef}
        onChange={selectedOption => {
          formik.handleChange("chef")(selectedOption);
        }}
        sx={{minWidth: 220, mt: 3}}
        variant="outlined"
      >
        {chefList.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.chefName}
          </MenuItem>
        ))}
      </TextField>
      <FormHelperText className="error-message">
        {formik.touched.chef && formik.errors.chef ?
          formik.errors.chef
          : ' '}
      </FormHelperText>

      <TextField
        id="regionCountry"
        select
        label="Country or Region*"
        value={formik.values.regionCountry}
        onChange={selectedOption => {
          formik.handleChange("regionCountry")(selectedOption);
        }}
        sx={{minWidth: 220, mt: 2,}}
        variant="outlined">
        {regionList.map(option => (
          <MenuItem key={option.countryCode} value={option.countryCode}>
            {option.countryLongName}
          </MenuItem>
        ))}
      </TextField>
      <FormHelperText className="error-message">
        {formik.touched.regionCountry && formik.errors.regionCountry ?
          formik.errors.regionCountry
          : ' '}
      </FormHelperText>

      <FormControl sx={{mt: 0, mb: 1}} fullWidth>
        <Badge sx={{textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500,}}>
          {formik.values.regionalInsight.length} / 275 characters
        </Badge>
        <TextField
          id="regionalInsight" label="Regional Insight" variant="outlined"
          multiline
          rows={4}
          defaultValue=""
          fullWidth
          inputProps={{
            maxLength: 275,
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </FormControl>

      <TextField
        id="segment"
        select
        label="Segment*"
        value={formik.values.segment ? formik.values.segment : ''}
        onChange={selectedOption => {
          formik.handleChange("segment")(selectedOption);
          fetchBrandsBySegmentId(selectedOption.target.value)
        }}
        sx={{minWidth: 220, mt: 2,}}
        variant="outlined">
        {segmentList.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <FormHelperText className="error-message">
        {formik.touched.segment && formik.errors.segment ?
          formik.errors.segment
          : ' '}
      </FormHelperText>

      <Box sx={{display: 'flex', width: '100%'}}>
        <Autocomplete
          id="brand-autocomplete"
          disabled={!formik.values.segment}
          options={filteredBrands}
          getOptionLabel={(option) => option.brandName || ""}
          sx={{minWidth: 220, mt: 0}}
          onChange={(event, value) => {
            formik.setFieldValue('brand', value ? value.id : '');
            setSearchTerm(undefined);
            setFilteredBrands(brandListWithOther);
            if (value && value.id !== OTHER_BRAND_OPTION.id) {
              formik.setFieldValue('brandOtherText', '')
            }
          }}
          inputValue={
            searchTerm !== undefined
              ? searchTerm
              : brandListWithOther.find(x => x.id === formik.values.brand)?.brandName || ''
          }
          value={brandListWithOther.find(x => String(x.id) === String(formik.values.brand)) || null}
          onInputChange={(event, newInputValue) => {
            if (newInputValue === '') {
              formik.setFieldValue('brandOtherText', '')
            }
            if (searchTerm === undefined && newInputValue === '') return;
            setSearchTerm(newInputValue);
            const processedInputValue = newInputValue.trim();
            if (processedInputValue) {
              const results = fuse.search(processedInputValue);
              setFilteredBrands(results.map(result => result.item));
            } else {
              setFilteredBrands(brandListWithOther);
            }
          }}
          filterOptions={(options) => options}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Brand"
              variant="outlined"
              margin="normal"
            />
          )}
          renderOption={(props, option) => {
            const isOtherBrandOption = option.id === OTHER_BRAND_OPTION.id;
            return (
              <li
                {...props}
                style={{
                  color: isOtherBrandOption ? '#a0c800' : '#464646',
                  fontWeight: isOtherBrandOption ? 'bold' : '400',
                  fontSize: '15px',
                  fontFamily: 'Source Sans Pro,sans-serif',
                  paddingTop: '6px',
                  paddingBottom: '6px',
                  lineHeight: '1.4em'
                }}
              >
                {option.brandName}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {formik.values.brand === OTHER_BRAND_OPTION.id || formik.values.brandOtherText ?
          <FormControl sx={{mt: 2, mx: 2, mb: -1}}>
            <TextField id="brandOtherText" label='Enter "Other" Brand Name' variant="outlined"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.brandOtherText ? formik.values.brandOtherText : ''}
                       sx={{minWidth: 570}}
                       inputProps={{
                         maxLength: 50,
                       }}
            />
          </FormControl>
          : null
        }
      </Box>
      <FormHelperText className="error-message">
        {formik.touched.brand && formik.errors.brand ?
          formik.errors.brand
          : ' '}
        {formik.touched.brandOtherText && formik.errors.brandOtherText ?
          formik.errors.brandOtherText
          : ' '}
      </FormHelperText>

      <Box sx={{mt: 5}}>
        <Divider textAlign="left">Health Choices:</Divider>
        <Typography variant="p" sx={{color: '#464646', fontSize: 15}}>
          Select the featured health attribute for this concept.
          <br/><em>*Multiple health choices can be applied</em>
        </Typography>
        <HealthChoices healthOptions={healthOptList}/>
      </Box>

      <FormControl sx={{mt: 3, width: '100%'}}>
        <InputLabel id="health-choices-select-label">Health Choices</InputLabel>
        <Select
          id="health-choices"
          labelId="health-choices-select-label"
          multiple
          label="Health Choices"
          value={formik.values.healthOptions ?? []}
          onChange={selectedOption => {
            formik.handleChange("healthOptions")(selectedOption);
          }}
          variant="outlined"
        >
          {healthOptList.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{mt: 5}}>
        <Divider textAlign="left">Featured Category:</Divider>
        <Typography variant="p" sx={{color: '#464646', fontSize: 15}}>
          Select up to 4 product categories and enter associated descriptions and codes
          {/*<ProductInnovationsModal productInnovations={productInnoList}/>*/}
          <br/><em>*Descriptions are limited to 140 characters each.</em>
        </Typography>
      </Box>

      {[...Array(visibleProducts)].map((_, index) => renderProductFields(index))}

      {visibleProducts < 4 && (
        <Button variant="outlined" onClick={handleAddProductFields} sx={{mt: 2}}>
          + Add Featured Category {visibleProducts + 1}
        </Button>
      )}

      <Divider textAlign="left" sx={{my: 3, mt: 5}}>Upload Concept Photo</Divider>
      <Typography variant="p" sx={{color: '#464646', fontSize: 15}}>
        For best results, concept photo needs to be exactly 1056px wide by 650px tall <em>OR</em> 528px wide by 325px
        tall.
      </Typography>
      <FormHelperText sx={{textAlign: 'right', mr: 2, color: '#ff9300'}}>
        {formik.touched.photo && formik.errors.photo ?
          formik.errors.photo
          : 'Maximum 5 MB'}
      </FormHelperText>
      <Box sx={{display: 'flex', width: '100%'}}>
        <FormControl sx={{
          mt: 2,
          mx: 2,
          mb: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%'
        }}>

          <Button variant="contained" component="label" size="large" sx={{color: '#fff', flex: '1 100%'}}>
            Select File
            <input hidden accept="image/png, image/jpeg, image/jpg" type="file" id="photo" name="photo"
                   onChange={(event) => {
                     setImage({
                       preview: URL.createObjectURL(event.currentTarget.files[0]),
                       raw: event.currentTarget.files[0]
                     });
                     formik.setFieldValue("photo", event.currentTarget.files[0]);
                     formik.handleChange(event)
                   }}
            />
          </Button>

          <FormLabel id="upload-group" sx={{fontSize: 17, fontWeight: 500, width: '100%'}}>
            {image.preview ? (
              <img src={image.preview} className="preview-image"/>
            ) : (
              <img src={fallbackSrc} className="preview-image"/>
            )}
          </FormLabel>

        </FormControl>
      </Box>

      <Box sx={{textAlign: 'center', width: '100%', mt: 6}}>
        <FormHelperText className="error-message" sx={{textAlign: 'center'}}>
          {(formik.touched.conceptName && formik.errors.conceptName)
          || (formik.touched.mealPart && formik.errors.mealPart)
          || (formik.touched.description && formik.errors.description)
          || (formik.touched.chef && formik.errors.chef)
          || (formik.touched.regionCountry && formik.errors.regionCountry)
          || (formik.touched.brandOtherText && formik.errors.brandOtherText)
          || (formik.touched.brand && formik.errors.brand)
          || (formik.touched.segment && formik.errors.segment)
          || (formik.touched.productCat1Description && formik.errors.productCat1Description)
          || (formik.touched.productCat1 && formik.errors.productCat1)
          || (formik.touched.productCat2Description && formik.errors.productCat2Description)
          || (formik.touched.productCat3Description && formik.errors.productCat3Description)
          || (formik.touched.productCat4Description && formik.errors.productCat4Description)
            ? 'Please enter all required fields.'
            : ' '}
        </FormHelperText>

        <Button
          variant="contained"
          color="secondary"
          size="large"
          type="submit"
          sx={{
            mt: 2,
            mb: 6,
            px: 6,
          }}

          disabled={savingConcept}
        >
          SAVE CONCEPT
        </Button>
      </Box>
      {!!snackbar && (
        <Snackbar open onClose={() => setSnackbar(null)} autoHideDuration={5000}>
          <Alert {...snackbar} onClose={() => setSnackbar(null)}/>
        </Snackbar>
      )}
    </form>

  );
}