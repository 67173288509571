import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import "./Footer.css"
import {getUser} from '../../store/userSlice'
import {useSelector} from 'react-redux'

export default function Footer() {
  const user = useSelector(getUser);

  return (
    <footer>
      <div className="footer-left">
        <Typography variant="subtitle1" sx={{ mt: 5, mb: 5 }}>©2025 Griffith Foods</Typography>

        {user.isAdmin ?
          <Typography variant="subtitle1" sx={{ mb: 2, fontSize: "0.7rem !important" }}>
            <Link href="/admin">Admin Login</Link>
          </Typography>
          :
          ''
        }

      </div>
      <div className="footer-right">
        <Typography variant="subtitle1" sx={{ mt: 5, mb: 5, textAlign: 'right' }}>
          <Link target="_blank" href="https://griffithfoods.service-now.com/">Griffith Employees</Link>&nbsp;&nbsp; |
          &nbsp;&nbsp;<Link target="_blank" href="https://griffithfoods.com/privacy-policy/">Griffith Foods™ Privacy Policy</Link>&nbsp;&nbsp;  |
          &nbsp;&nbsp;<Link target="_blank" href="https://griffithfoods.com/terms/">Terms of Use Agreement</Link>
        </Typography>
      </div>
    </footer>
  )
}


