import React from "react";
import ChefsManager from "../../components/admin/menu-filters/ChefsManager";
import MealPartsManager from "../../components/admin/menu-filters/MealPartsManager";
import BrandsManager from "../../components/admin/menu-filters/BrandsManager";
import ProductCategoryManager from "../../components/admin/menu-filters/ProductCategoryManager";
import "./ManageConceptLockerFilters.css"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function ManageConceptLockerFilters() {


  return (

    <main>
      <Stack spacing={2} direction="row" sx={{margin: "20px auto 0", maxWidth: 1250}}>

        <Button
          variant="contained"
          color="secondary"
          size="large"

          href="/admin"
        >
          ADMIN DASHBOARD
        </Button>

      </Stack>
      <Container maxWidth="xl" sx={{paddingBottom: 25}}>
        <Box sx={{my: 4}}>
          <Typography variant="h4" align="center">
            Manage Concept Locker Filters
          </Typography>
        </Box>

        <Box sx={{flexGrow: 1}}>
          <Grid container spacing={5} sx={{justifyContent: 'center'}}>

            <Grid item xs={5}>
              <Typography gutterBottom variant="h6" align="center">Meal Parts</Typography>
              <MealPartsManager/>
            </Grid>
            <Grid item xs={5}>
              <Typography gutterBottom variant="h6" align="center">Featured Category</Typography>
              <ProductCategoryManager/>
            </Grid>

            <Grid item xs={6}>
              <Typography gutterBottom variant="h6" align="center">Brands</Typography>
              <BrandsManager/>
            </Grid>
            <Grid item xs={9}>
              <Typography gutterBottom variant="h6" align="center">Chefs</Typography>
              <ChefsManager/>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </main>

  );


}



