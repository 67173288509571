import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {makeStyles} from "@material-ui/core/styles";
import "./MenuItems.css";
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import CardActions from '@mui/material/CardActions';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import {Link,} from "react-router-dom";

import {format} from 'date-fns'
import {fetchSavedSearches, selectSavedSearches, updateSavedSearchName} from '../store/savedSearchesSlice'
import {getUser} from '../store/userSlice'
import {styled} from '@mui/material/styles';

const SavedSearches = () => {
  const [country, setCountry] = React.useState('US');
  const [countryList, setCountryList] = React.useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = React.useState(new Date(2022, 12, 2));
  const [toDate, setToDate] = React.useState(new Date(now.getFullYear(), now.getMonth(), 1));
  const [keyword, setKeyword] = React.useState('');
  const [savedSearchName, setSavedSearchName] = React.useState('');
  const [savedSearchId, setSavedSearchId] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();
  const savedSearches = useSelector(selectSavedSearches)
  const user = useSelector(getUser);

  const [snackbar, setSnackbar] = React.useState(null);

  useEffect(() => {
    dispatch(fetchSavedSearches({data: {staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword}}))
  }, [keyword, fromDate, toDate, dispatch])

  const valueRef = useRef(false)

  const sendValue = () => {
    return console.log(valueRef.current.value) //on clicking button accesing current value of TextField and outputing it to console 
  }


  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  function editSavedSearch(id, name) {
    setSavedSearchId(id);
    setSavedSearchName(name);
    handleOpen(true);

  }

  function saveName() {
    dispatch(updateSavedSearchName({savedSearchName, savedSearchId})).then(() => {
      setSnackbar({children: 'Search Updated successfully', severity: 'success'});
    })
      .then(function () {
        dispatch(fetchSavedSearches({data: {staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword}}));
        handleClose(true);
      })
  }

  const useStyles = makeStyles(theme => ({
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    menuPaper: {
      maxHeight: 300
    }

  }));

  const handleChange = (prop) => (event) => {
    setValues({...values, [prop]: event.target.value});
  };

  const [savedSearchesToExport, setSavedSearchesToExport] = useState([]);

  const style = {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%,-50%)',
    width: 487,
    bgcolor: '#f7f7f7',
    border: 'none',
    boxShadow: 24,
    height: 200,
    overflowY: 'none',
    overflowX: 'none',
    p: 4,

  };

  const ColorButton = styled(Button)(({theme}) => ({
    color: theme.palette.getContrastText("#fafafa"),
    backgroundColor: "#fafafa",
    '&:hover': {
      backgroundColor: "#f5f5f5",
    },
  }));


  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography color="primary">
            Rename search:
            <TextField
              sx={{width: "100%", my: 2, mx: 0}}
              id="search-input"
              size="small"
              placeholder={savedSearchName}
              InputLabelProps={{shrink: true}}
              value={savedSearchName}
              onChange={event => {
                const {value} = event.target;
                setSavedSearchName(value);
              }}
            />
          </Typography>

          <CardActions sx={{padding: 0}}>
            <Button variant="contained" color="primary" sx={{color: "#fff"}}
                    onClick={() => {
                      saveName()
                    }}
            >Save</Button>
          </CardActions>
        </Box>

      </Modal>
      <Container
        sx={{
          width: "1150px",
          display: 'flex',
          flexWrap: 'nowrap',
          mt: 2,
          mb: 2
        }} fixed>

        <FormControl sx={{m: 1}} variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Date From"
              value={fromDate}
              minDate={new Date('2023-01-02')}
              maxDate={new Date()}
              onChange={(newFromDate) => {
                setFromDate(newFromDate);
              }}
              openTo='month'
              disableCloseOnSelect={false}
              renderInput={(params) =>
                <TextField {...params} className="date-picker" helperText={null} name="fromDate" size="small"/>}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{m: 1}} variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Date To"
              openTo='month'
              value={toDate}
              minDate={new Date('2023-01-02')}
              maxDate={new Date(Date.now())}
              disableCloseOnSelect={false}
              onChange={(newToDate) => {
                setToDate(newToDate);
              }}
              renderInput={(params) => <TextField {...params} className="date-picker" helperText={null} name="toDate"
                                                  size="small"/>}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{m: 1, width: '500px'}} variant="outlined">
          <TextField
            label="Keyword Search"
            type="search"
            fullWidth
            id="search-input"
            size="small"
            placeholder='Search through your presentations'
            InputLabelProps={{shrink: true}}
            value={keyword}
            onChange={event => {
              const {value} = event.target;
              setKeyword(value);
            }}
            renderInput={(params) => <TextField {...params} className="text"
                                                helperText={'Search through your presentations'} name="keyword"
                                                size="small"/>}
            InputProps={{
              endAdornment: <InputAdornment position="end"><SearchIcon className='search-icon'/></InputAdornment>,
            }}
          />
        </FormControl>
        <FormControl sx={{m: 1, width: '135px'}}>
          <Button
            sx={{color: "#fff"}}
            variant="contained"
            color="primary"
            disableElevation
          >Search
          </Button>
        </FormControl>

      </Container>

      <Container
        sx={{
          width: "1150px",
          display: 'flex',
          flexWrap: 'nowrap',
          mt: 2,
          mb: 2,
          justifyContent: 'space-between',
          backgroundColor: '#f7f7f7',
          pt: 1,
          pb: 1
        }} fixed>
        <Typography gutterBottom variant="body2" component="div" sx={{mt: 1, mr: 1}}>
          <em>My Saved Searches:</em>
        </Typography>

        <Box sx={{
          alignItems: 'center',
          display: 'flex',
        }}>

          <ColorButton
            startIcon={<DeleteIcon/>} variant="body2"
            onClick={() => {
              try {
                const response = axios.post('api/SavedSearch/DeleteSavedSearch', savedSearchesToExport, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                  },
                })
                  .then(function (results) {
                    dispatch(fetchSavedSearches({
                      data: {
                        staff: user.id,
                        fromDate: fromDate,
                        toDate: toDate,
                        keyword: keyword
                      }
                    }))
                  })

                return [...response.data];
              } catch (err) {
                return err.message;
              }
            }}
          >Delete Selected</ColorButton>
        </Box>
      </Container>

      <Container
        sx={{
          maxWidth: "1340px",
          display: 'flex',
          flexWrap: 'nowrap',
          mt: 2,
          mb: 2,
          justifyContent: 'space-between',
        }} fixed>
      </Container>

      <Container
        sx={{
          maxWidth: "1340px",
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          mb: 2,
        }} fixed>

        {savedSearches?.map((savedSearch) => {

          const filters = JSON.parse(savedSearch.searchFilters);
          let filterContentStr = '';

          const filterLabels = {
            fromDate: () => {
              const displayFromDate = new Date(0);
              displayFromDate.setUTCSeconds(filters.fromDate);
              const displayToDate = new Date(0);
              displayToDate.setUTCSeconds(filters.toDate);
              return `Date Range: ${format(displayFromDate, 'MMM. yyyy')} - ${format(displayToDate, 'MMM. yyyy')}`;
            },
            query: 'Keyword Query',
            country: 'Country',
            chef: 'Chef',
            segment: 'Segment',
            brand: 'Brand',
            healthOptions: 'Health Choices',
            mealpart: 'Meal Part',
            featuredProducts: 'Featured Category',
            // innovations: 'Innovations',
          };

          Object.keys(filterLabels).forEach((key) => {
            const value = filters[key];

            if (key === 'fromDate' && filters.fromDate > 0 && filters.toDate > 0) {
              filterContentStr += filterLabels.fromDate() + ' | ';
            } else if (value && value.length > 0) {
              filterContentStr += `${filterLabels[key]}: ${value} | `;
            }
          });

          return (

            <Box id={savedSearch.id} sx={{
              alignItems: 'center',
              display: 'flex',
              borderTop: '2px solid #afafaf',
              pt: 2,
              pb: 2,
            }} className="menu-item-row" key={savedSearch.id}>

              <FormControlLabel
                className="checkbox-label" sx={{mr: 0}}
                control={
                  <Checkbox/>}
                label=""
                checked={valueRef.checked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSavedSearchesToExport([
                      ...savedSearchesToExport,
                      {
                        id: savedSearch.id
                      },
                    ]);
                  } else {
                    setSavedSearchesToExport(
                      savedSearchesToExport.filter(present => present.id !== savedSearch.id),
                    );
                  }
                }}
                aria-describedby={savedSearch.id}
                inputRef={valueRef}
              />
              <Box className="menu-item-row--details"
                   sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     width: '90%',
                     ml: 3
                   }}>
                <Typography gutterBottom variant="h4" component="div" sx={{fontSize: '1.7rem', mt: 0, mr: 1, mb: 0}}>
                  <em><Link to={{pathname: "/concepts", search: "?search=" + savedSearch.id}}
                            style={{color: '#ff9300', textDecoration: 'none'}}>{savedSearch.searchName}</Link></em>

                  &nbsp; <ColorButton startIcon={<EditIcon/>} variant="body2" onClick={() => {
                  editSavedSearch(savedSearch.id, savedSearch.searchName);
                }}>Rename</ColorButton>

                </Typography>
                <Typography gutterBottom variant="body2" component="div" sx={{mt: 1, mr: 1, mb: 0}}>
                  <em>Search Saved on: {format(new Date(savedSearch.searchSavedDate), 'PPPpp')}</em>

                </Typography>
                <Typography color="black" gutterBottom variant="h6" component="div"
                            sx={{fontSize: '1.22rem', mt: 1, mr: 1, mb: 0}}>
                  <em>Search Parameters Used:</em>
                </Typography>
                <Typography gutterBottom variant="body2" component="div" sx={{mt: 1, mr: 1, mb: 0}}>
                  <em>{filterContentStr.slice(0, filterContentStr.length - 2)}</em>
                </Typography>
                <Box sx={{
                  flexDirection: 'column',
                  display: 'flex'
                }}>
                </Box>
              </Box>
            </Box>
          )
        })}
      </Container>
      {!!snackbar && (
        <Snackbar open onClose={() => setSnackbar(null)} autoHideDuration={5000}>
          <Alert {...snackbar} onClose={() => setSnackbar(null)}/>
        </Snackbar>
      )}
    </>
  );
}
export default SavedSearches