import React, {useEffect, useMemo, useState} from "react";
import "./ManageCulinaryConcepts.css"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  TextField
} from '@mui/material';
import dayjs from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import uploadFileToBlob from '../../components/admin/upload/AzureBlobStorage'
import {format} from 'date-fns'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {useDispatch} from 'react-redux'
import {unwrapResult} from '@reduxjs/toolkit'
import {updateConcept} from '../../store/conceptsSlice'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Stack from '@mui/material/Stack';
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import HealthChoices from "../../components/admin/culinary-concepts/HealthOptionsModal";
import Fuse from "fuse.js";

export default function EditCulinaryConcepts() {
  const {conceptId} = useParams()
  const [concept, setConcept] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const containerName = `concept-locker`;
  const storageAccountName = `flavoriqblobstorage`;
  const fallbackSrc = "/images/default-ppt.png";
  const [image, setImage] = useState({preview: fallbackSrc, raw: ""});
  const [originalImageName, setOriginalImageName] = useState(null);

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const elementId = location.hash.replace('#', ''); // Remove the '#' symbol
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [location]);

  const fetchBrandsBySegmentId = async (segmentId) => {
    try {
      let response = await fetch(`api/brands/segment/${segmentId}`);
      if (response.status === 200) {
        let data = await response.json();
        setBrandList(data);
      } else {
        throw `Error fetching brand list for segment id ${segmentId}`;
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetch('api/conceptitems/GetConceptItem/' + conceptId)
      .then((data) => data.json())
      .then((data) => {
        //  console.log("DATA:: " + JSON.stringify(data))
        if (data.blobImageName) {
          setOriginalImageName(data.blobImageName);
          let imagePath = `https://${storageAccountName}.blob.core.windows.net/${containerName}/images/concepts/`
            + format(new Date(data.creationDate), 'yyyy') + '/'
            + format(new Date(data.creationDate), 'MMM').toLowerCase() + '/' + data.blobImageName;
          data.blobImageName = imagePath;
        } else {
          data.blobImageName = fallbackSrc;
        }
        setConcept(data)
        if (data.segment) {
          fetchBrandsBySegmentId(data.segment)
        }
      })
  }, [])

  return (
    <>
      <Stack spacing={2} direction="row" sx={{margin: "20px auto 0", maxWidth: 1250}}>

        <Button
          variant="contained"
          color="secondary"
          size="large"

          href="/admin/"
        >
          ADMIN DASHBOARD
        </Button>

      </Stack>
      <ConceptsFormik
        fetchBrandsBySegmentId={fetchBrandsBySegmentId}
        brandList={brandList}
        concept={{
          id: concept.id,
          conceptName: concept.conceptName,
          mealPart: concept.mealPart,
          description: concept.description,
          creationDate: concept.creationDate,
          chef: concept.chef,
          regionCountry: concept.regionCountry,
          regionalInsight: concept.regionalInsight,
          brand: concept.brand ? concept.brand : 999,
          segment: concept.segment,
          brandOtherText: concept.brandOtherText,
          healthOptions: concept.healthOptions,
          productCat1: concept.productCat1,
          productCat1Description: concept.productCat1Description,
          // productCat1Innovation: concept.productCat1Innovation,
          productCat1Code: concept.productCat1Code,
          productCat2: concept.productCat2,
          productCat2Description: concept.productCat2Description,
          // productCat2Innovation: concept.productCat2Innovation,
          productCat2Code: concept.productCat2Code,
          productCat3: concept.productCat3,
          productCat3Description: concept.productCat3Description,
          // productCat3Innovation: concept.productCat3Innovation,
          productCat3Code: concept.productCat3Code,
          productCat4: concept.productCat4,
          productCat4Description: concept.productCat4Description,
          // productCat4Innovation: concept.productCat4Innovation,
          productCat4Code: concept.productCat4Code,
          photoURL: concept.blobImageName,
          originalImageName: originalImageName,
          publishedDate: concept.publishedDate,
        }}/>
    </>
  )
}

const ConceptsFormik = (props) => {
  const {fetchBrandsBySegmentId, brandList} = props;
  const fallbackSrc = "/images/default-ppt.png";
  const [chefList, setChefList] = useState([]);
  const [mealpartList, setMealPartList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [healthOptList, setHealthOptList] = useState([]);
  // const [productInnoList, setProductInnoList] = useState([]);
  const [productCatList, setProductCatList] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const FILE_SIZE_MAX = 500000
  const [image, setImage] = useState({preview: fallbackSrc, raw: ""});
  const [savingConcept, setSavingConcept] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [filteredBrands, setFilteredBrands] = useState(brandList); // State for filtered brands

  const OTHER_BRAND_OPTION = {id: '999', brandName: 'Other Brand'};
  const brandListWithOther = useMemo(() => {
    // Sort brandList alphabetically and place OTHER_BRAND_OPTION at the top
    const sortedBrands = [...brandList].sort((a, b) => a.brandName.localeCompare(b.brandName));
    return [OTHER_BRAND_OPTION, ...sortedBrands];
  }, [brandList]);

  const fuse = useMemo(() => {
    return new Fuse(brandListWithOther, {
      keys: ['brandName'],
      includeScore: true,
      shouldSort: true,
      threshold: 0.3,
      distance: 300,
    });
  }, [brandListWithOther]);

  useEffect(() => {
    setFilteredBrands(brandListWithOther);
  }, [brandListWithOther]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PRODUCT_DESCRIPTION_MAX_LENGTH = 140;
  const PRODUCT_CODE_MAX_LENGTH = 50;

  useEffect(() => {
    fetch('api/mealparts')
      .then((data) => data.json())
      .then((data) => setMealPartList(data))

    fetch('api/chefs')
      .then((data) => data.json())
      .then((data) => setChefList(data), [])

    fetch('api/countries')
      .then((data) => data.json())
      .then((data) => setRegionList(data.sort((a, b) => a.countryLongName.localeCompare(b.countryLongName))))

    fetch('api/healthoptions')
      .then((data) => data.json())
      .then((data) => setHealthOptList(data))

    // fetch('api/ProductInnovations')
    //   .then((data) => data.json())
    //   .then((data) => setProductInnoList(data))

    fetch('api/productcategories')
      .then((data) => data.json())
      .then((data) => setProductCatList(data))

    fetch('api/segments')
      .then((data) => data.json())
      .then((data) => setSegmentList(data))
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.concept.id,
      conceptName: props.concept.conceptName,
      mealPart: props.concept.mealPart,
      description: props.concept.description,
      creationDate: props.concept.creationDate,
      chef: props.concept.chef,
      regionCountry: props.concept.regionCountry,
      regionalInsight: props.concept.regionalInsight,
      brand: props.concept.brand,
      segment: props.concept.segment,
      brandOtherText: props.concept.brandOtherText,
      healthOptions: props.concept.healthOptions?.map(option => option.id) ?? [],
      productCat1: props.concept.productCat1,
      productCat1Description: props.concept.productCat1Description,
      // productCat1Innovation: props.concept.productCat1Innovation,
      productCat1Code: props.concept.productCat1Code,
      productCat2: props.concept.productCat2,
      productCat2Description: props.concept.productCat2Description,
      // productCat2Innovation: props.concept.productCat2Innovation,
      productCat2Code: props.concept.productCat2Code,
      productCat3: props.concept.productCat3,
      productCat3Description: props.concept.productCat3Description,
      // productCat3Innovation: props.concept.productCat3Innovation,
      productCat3Code: props.concept.productCat3Code,
      productCat4: props.concept.productCat4,
      productCat4Description: props.concept.productCat4Description,
      // productCat4Innovation: props.concept.productCat4Innovation,
      productCat4Code: props.concept.productCat4Code,
      photoURL: props.concept.photoURL,
      photo: null,
      publishedDate: props.concept.publishedDate,
    },
    validationSchema: Yup.object({
      conceptName: Yup.string()
        .required('Concept name is required'),
      mealPart: Yup.string()
        .required('Meal part is required'),
      description: Yup.string()
        .required('Description is required'),
      chef: Yup.string()
        .required('Chef is required'),
      regionCountry: Yup.string()
        .required('Country or region is required'),
      segment: Yup.string()
        .required('Segment is required'),
      brand: Yup.string()
        .required('Brand is required'),
      brandOtherText: Yup.string().ensure().when('brand', {
        is: OTHER_BRAND_OPTION.id,
        then: Yup.string().required('Brand is required')
      }),
      productCat1: Yup.number()
        .required('At least one product category is required'),
      productCat1Description: Yup.string().when('productCat1', {
        is: (productCat1) => productCat1,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat2Description: Yup.string().when('productCat2', {
        is: (productCat2) => productCat2,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat3Description: Yup.string().when('productCat3', {
        is: (productCat3) => productCat3,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat4Description: Yup.string().when('productCat4', {
        is: (productCat4) => productCat4,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string().nullable(true),
      }),
      // photo: Yup.mixed()
      //    .nullable(true)
      //    .notRequired()
      //    .test("FILE_SIZE_MAX", "Uploaded file is too big. Maximum 5 MB.",
      //      value => value && (value.size <= FILE_SIZE_MAX)),
    }),
    onSubmit: values => {
      //if not default image push image to azure
      if (values.photo) {
        onImageUpload(image.raw)
      }
      saveConcept(values)
    },
  });


  function transFormToNumber(value) {
    return value === 0 ? null : Number(value);
  }

  function saveConcept(formData) {
    if (savingConcept)
      return

    var fileName = null
    if (image.raw) {
      fileName = image.raw.name

    } else if (props.concept.originalImageName) {
      fileName = props.concept.originalImageName
    }

    const healthOptions = healthOptList.filter(opt => formData.healthOptions.some(s => s === opt.id));

    var data = {
      id: formData.id,
      ConceptName: formData.conceptName,
      MealPart: formData.mealPart,
      Brand: formData.brand,
      BrandOtherText: formData.brandOtherText,
      Segment: transFormToNumber(formData.segment),
      Chef: formData.chef,
      Description: formData.description,
      CreationDate: new Date(formData.creationDate).toISOString(),
      RegionCountry: formData.regionCountry,
      RegionalInsight: formData.regionalInsight,
      BlobImageName: fileName,
      healthOptions: healthOptions,
      ProductCat1: transFormToNumber(formData.productCat1),
      ProductCat1Description: formData.productCat1Description,
      // ProductCat1Innovation: formData.productCat1Innovation,
      ProductCat1Code: formData.productCat1Code,
      ProductCat2: transFormToNumber(formData.productCat2),
      ProductCat2Description: formData.productCat2Description,
      // ProductCat2Innovation: formData.productCat2Innovation,
      ProductCat2Code: formData.productCat2Code,
      ProductCat3: transFormToNumber(formData.productCat3),
      ProductCat3Description: formData.productCat3Description,
      // ProductCat3Innovation: formData.productCat3Innovation,
      ProductCat3Code: formData.productCat3Code,
      ProductCat4: transFormToNumber(formData.productCat4),
      ProductCat4Description: formData.productCat4Description,
      // ProductCat4Innovation: formData.productCat4Innovation,
      ProductCat4Code: formData.productCat4Code,
      PublishedDate: props.concept.publishedDate
    };

    console.log("save data:::" + JSON.stringify(data))
    setSavingConcept(true);

    dispatch(updateConcept(data))
      .then(unwrapResult).then(originalPromiseResult => {
      setSavingConcept(false);
      setSnackbar({children: 'Concept saved successfully', severity: 'success'});

    }).then(() => {
      if (props.concept.publishedDate) {
        navigate('/concepts')
      } else {
        navigate('/admin')
      }
    })

      .catch(rejectedValueOrSerializedError => {
        console.log(rejectedValueOrSerializedError);
        setSavingConcept(false);
        setSnackbar({children: 'Failed to save concept', severity: 'error'});
      });
  }

  const onImageUpload = async (file) => {
    // *** UPLOAD TO AZURE STORAGE ***  
    let imagePath = '/images/concepts/'
      + format(new Date(formik.values.creationDate), 'yyyy') + '/'
      + format(new Date(formik.values.creationDate), 'MMM').toLowerCase() + '/';
    await uploadFileToBlob(imagePath, file);
  }

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const calculateInitialVisibleProducts = () => {
    const productCatValues = [
      formik.values.productCat1,
      formik.values.productCat2,
      formik.values.productCat3,
      formik.values.productCat4
    ];

    return productCatValues.filter((cat) => cat !== undefined && cat !== null && cat !== '').length || 1;
  };

  const [visibleProducts, setVisibleProducts] = useState(1);

  useEffect(() => {
    const initialVisibleProducts = calculateInitialVisibleProducts();
    setVisibleProducts(initialVisibleProducts);
  }, [formik.values]);

  const handleAddProductFields = () => {
    setVisibleProducts((prev) => (prev < 4 ? prev + 1 : prev));
  };

  const renderProductFields = (index) => (
    <Box key={index}>
      <Box sx={{display: 'inline-flex', width: '100%', justifyContent: 'space-between'}}>
        <Badge sx={{mt: 2, width: '78%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500}}>
          {formik.values[`productCat${index + 1}Description`]?.length || 0} / {PRODUCT_DESCRIPTION_MAX_LENGTH} characters
        </Badge>
        <Badge sx={{mt: 2, width: '20%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500}}>
          {formik.values[`productCat${index + 1}Code`]?.length || 0} / {PRODUCT_CODE_MAX_LENGTH} characters
        </Badge>
      </Box>

      <Box sx={{display: 'flex', width: '100%', gap: '15px'}}>
        <TextField
          id={`productCat${index + 1}`}
          select
          label={`Featured Category ${index + 1}` + (index === 0 ? '*' : '')}
          value={formik.values[`productCat${index + 1}`] ?? ''}
          onChange={(event) => formik.setFieldValue(`productCat${index + 1}`, event.target.value || 1)}
          sx={{width: 275}}
          variant="outlined"
        >
          {productCatList.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.categoryName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id={`productCat${index + 1}Description`}
          label="Description"
          variant="outlined"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[`productCat${index + 1}Description`] ?? ''}
          sx={{width: 350}}
          inputProps={{maxLength: PRODUCT_DESCRIPTION_MAX_LENGTH}}
        />

        {/*<TextField*/}
        {/*  id={`productCat${index + 1}Innovation`}*/}
        {/*  select*/}
        {/*  label="Innovation"*/}
        {/*  value={formik.values[`productCat${index + 1}Innovation`] ?? ''}*/}
        {/*  onChange={(event) => formik.setFieldValue(`productCat${index + 1}Innovation`, event.target.value || null)}*/}
        {/*  sx={{width: 162.8}}*/}
        {/*  variant="outlined"*/}
        {/*>*/}
        {/*  <MenuItem value="">*/}
        {/*    <em>None</em>*/}
        {/*  </MenuItem>*/}
        {/*  {productInnoList.map(option => (*/}
        {/*    <MenuItem key={option.id} value={option.id}>*/}
        {/*      {option.name}*/}
        {/*    </MenuItem>*/}
        {/*  ))}*/}
        {/*</TextField>*/}

        <TextField
          id={`productCat${index + 1}Code`}
          label="Product Code"
          variant="outlined"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[`productCat${index + 1}Code`] ?? ''}
          sx={{width: 162.8}}
          inputProps={{maxLength: PRODUCT_CODE_MAX_LENGTH}}
        />
      </Box>

      <FormHelperText className="error-message">
        {formik.touched[`productCat${index + 1}Description`] && formik.errors[`productCat${index + 1}Description`]
          ? formik.errors[`productCat${index + 1}Description`]
          : ' '}
        {index === 0 && formik.touched.productCat1 && formik.errors.productCat1 ?
          formik.errors.productCat1
          : ' '
        }
      </FormHelperText>
    </Box>
  );

  return (
    <Container
      sx={{
        maxWidth: "1340px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 2,
        mb: 2,
      }} fixed>

      <Box sx={{my: 4}}>
        <Typography variant="h4" align="center">
          Edit Culinary Concept
        </Typography>
      </Box>

      <Box className=""
           sx={{
             width: '70%'
           }}>

        <form onSubmit={formik.handleSubmit}>

          <FormControl sx={{mt: 2}} fullWidth>
            <Badge sx={{textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500,}}>
              {formik.values.conceptName ? formik.values.conceptName.length : 0} / 150 characters
            </Badge>
            <TextField id="conceptName" label="Concept Name*" variant="outlined"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.conceptName ? formik.values.conceptName : ''}
                       fullWidth
                       inputProps={{
                         maxLength: 150,
                       }}
            />
          </FormControl>
          <FormHelperText className="error-message">
            {formik.touched.conceptName && formik.errors.conceptName ?
              formik.errors.conceptName
              : ' '}
          </FormHelperText>

          <TextField
            id="mealPart"
            select
            label="Meal Part*"
            value={formik.values.mealPart ? formik.values.mealPart : ''}
            onChange={selectedOption => {
              formik.handleChange("mealPart")(selectedOption);
            }}
            sx={{minWidth: 220, mt: 2,}}
            variant="outlined"
          >
            {mealpartList.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.mealPartName}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText className="error-message">
            {formik.touched.mealPart && formik.errors.mealPart ?
              formik.errors.mealPart
              : ' '}
          </FormHelperText>

          <FormControl fullWidth>
            <Badge sx={{textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500,}}>
              {formik.values.description ? formik.values.description.length : 0} / 275 characters
            </Badge>
            <TextField
              id="description" label="Concept Description*" variant="outlined"
              multiline
              rows={4}
              value={formik.values.description ? formik.values.description : ''}
              fullWidth
              inputProps={{
                maxLength: 275,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormHelperText className="error-message">
            {formik.touched.description && formik.errors.description ?
              formik.errors.description
              : ' '}
          </FormHelperText>

          <Box sx={{display: 'block', width: '100%'}}>
            <FormControl sx={{mb: 2, mt: 4,}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year', 'month']}
                  label="Creation Date*"
                  openTo='month'
                  minDate={dayjs('2023-01-15')}
                  maxDate={dayjs('2023-12-15')}
                  value={dayjs(formik.values.creationDate)}
                  disabled={true}
                  onChange={(value) => {
                    formik.setFieldValue('creationDate', Date.parse(value));
                  }}
                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} helperText={null}
                                                      name="creationDate"/>}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>

          <TextField
            id="chef"
            select
            label="Chef*"
            value={formik.values.chef ? formik.values.chef : ''}
            onChange={selectedOption => {
              formik.handleChange("chef")(selectedOption);
            }}
            sx={{minWidth: 220, mt: 3,}}
            variant="outlined"
          >
            {chefList.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.chefName}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText className="error-message">
            {formik.touched.chef && formik.errors.chef ?
              formik.errors.chef
              : ' '}
          </FormHelperText>

          <TextField
            id="regionCountry"
            select
            label="Country or Region*"
            value={formik.values.regionCountry ? formik.values.regionCountry : ''}
            onChange={selectedOption => {
              formik.handleChange("regionCountry")(selectedOption);
            }}
            sx={{minWidth: 220, mt: 2,}}
            variant="outlined">
            {regionList.map(option => (
              <MenuItem key={option.countryCode} value={option.countryCode}>
                {option.countryLongName}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText className="error-message">
            {formik.touched.regionCountry && formik.errors.regionCountry ?
              formik.errors.regionCountry
              : ' '}
          </FormHelperText>

          <FormControl sx={{mt: 0, mb: 1}} fullWidth>
            <Badge sx={{textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500,}}>
              {formik.values.regionalInsight ? formik.values.regionalInsight.length : 0} / 275 characters
            </Badge>
            <TextField
              id="regionalInsight" label="Regional Insight" variant="outlined"
              multiline
              rows={4}
              value={formik.values.regionalInsight ? formik.values.regionalInsight : ''}
              fullWidth
              inputProps={{
                maxLength: 275,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>

          <TextField
            id="segment"
            select
            label="Segment*"
            value={formik.values.segment ? formik.values.segment : ''}
            onChange={selectedOption => {
              formik.handleChange("segment")(selectedOption);
              fetchBrandsBySegmentId(selectedOption.target.value)
            }}
            sx={{minWidth: 220, mt: 2,}}
            variant="outlined">
            {segmentList.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText className="error-message">
            {formik.touched.segment && formik.errors.segment ?
              formik.errors.segment
              : ' '}
          </FormHelperText>

          <Box sx={{display: 'flex', width: '100%'}}>
            <Autocomplete
              id="brand-autocomplete"
              disabled={!formik.values.segment}
              options={filteredBrands}
              getOptionLabel={(option) => option.brandName || ""}
              sx={{minWidth: 220, mt: 0}}
              onChange={(event, value) => {
                formik.setFieldValue('brand', value ? value.id : '');
                setSearchTerm(undefined);
                setFilteredBrands(brandListWithOther);
                if (value && value.id !== OTHER_BRAND_OPTION.id) {
                  formik.setFieldValue('brandOtherText', '')
                }
              }}
              inputValue={
                searchTerm !== undefined
                  ? searchTerm
                  : brandListWithOther.find(x => x.id === formik.values.brand)?.brandName || ''
              }
              value={brandListWithOther.find(x => String(x.id) === String(formik.values.brand)) || null}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === '') {
                  formik.setFieldValue('brandOtherText', '')
                }
                if (searchTerm === undefined && newInputValue === '') return;
                setSearchTerm(newInputValue);
                const processedInputValue = newInputValue.trim();
                if (processedInputValue) {
                  const results = fuse.search(processedInputValue);
                  setFilteredBrands(results.map(result => result.item));
                } else {
                  setFilteredBrands(brandListWithOther);
                }
              }}
              filterOptions={(options) => options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Brand"
                  variant="outlined"
                  margin="normal"
                />
              )}
              renderOption={(props, option) => {
                const isOtherBrandOption = option.id === OTHER_BRAND_OPTION.id;
                return (
                  <li
                    {...props}
                    style={{
                      color: isOtherBrandOption ? '#a0c800' : '#464646',
                      fontWeight: isOtherBrandOption ? 'bold' : '400',
                      fontSize: '15px',
                      fontFamily: 'Source Sans Pro,sans-serif',
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      lineHeight: '1.4em'
                    }}
                  >
                    {option.brandName}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {formik.values.brand === OTHER_BRAND_OPTION.id || formik.values.brandOtherText ?
              <FormControl sx={{mt: 2, mx: 2, mb: -1}}>
                <TextField id="brandOtherText" label='Enter "Other" Brand Name' variant="outlined"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.brandOtherText ? formik.values.brandOtherText : ''}
                           sx={{minWidth: 570}}
                           inputProps={{
                             maxLength: 50,
                           }}
                />
              </FormControl>
              : null
            }
          </Box>
          <FormHelperText className="error-message">
            {formik.touched.brand && formik.errors.brand ?
              formik.errors.brand
              : ' '}
            {formik.touched.brandOtherText && formik.errors.brandOtherText ?
              formik.errors.brandOtherText
              : ' '}
          </FormHelperText>

          <Box sx={{mt: 5}}>
            <Divider textAlign="left">Health Choices:</Divider>
            <Typography variant="p" sx={{color: '#464646', fontSize: 15}}>
              Select the featured health attribute for this concept.
              <br/><em>*Multiple health choices can be applied</em>
            </Typography>
            <HealthChoices healthOptions={healthOptList}/>
          </Box>

          <FormControl sx={{mt: 3, width: '100%'}}>
            <InputLabel id="health-choices-select-label">Health Choices</InputLabel>
            <Select
              id="health-choices"
              labelId="health-choices-select-label"
              multiple
              label="Health Choices"
              value={formik.values.healthOptions ?? []}
              onChange={selectedOption => {
                console.log(selectedOption)
                formik.handleChange("healthOptions")(selectedOption);
              }}
              variant="outlined"
            >
              {healthOptList.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{mt: 5}}>
            <Divider textAlign="left">Featured Category:</Divider>
            <Typography variant="p" sx={{color: '#464646', fontSize: 15}}>
              Select up to 4 product categories and enter associated descriptions and codes.
              {/*<ProductInnovationsModal productInnovations={productInnoList}/>*/}
              <br/><em>*Descriptions are limited to 140 characters each.</em>
            </Typography>
          </Box>

          {[...Array(visibleProducts)].map((_, index) => renderProductFields(index))}

          {visibleProducts < 4 && (
            <Button variant="outlined" onClick={handleAddProductFields} sx={{mt: 2}}>
              + Add Featured Category {visibleProducts + 1}
            </Button>
          )}

          <Divider textAlign="left" sx={{my: 3, mt: 5}}>Upload Concept Photo</Divider>
          <Typography variant="p" sx={{color: '#464646', fontSize: 15}}>
            For best results, concept photo needs to be exactly 1056px wide by 650px tall <em>OR</em> 528px wide by
            325px tall.
          </Typography>

          <FormHelperText sx={{textAlign: 'right', mr: 2, color: '#ff9300'}}>
            {formik.touched.photo && formik.errors.photo ?
              formik.errors.photo
              : 'Maximum 5 MB'}
          </FormHelperText>
          <Box sx={{display: 'flex', width: '100%'}}>
            <FormControl sx={{
              mt: 2,
              mx: 2,
              mb: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100%'
            }}>

              <Button variant="contained" component="label" size="large" sx={{color: '#fff', flex: '1 100%'}}>
                Select File
                <input hidden accept="image/png, image/jpeg, image/jpg" type="file" id="photo" name="photo"
                       onChange={(event) => {
                         setImage({
                           preview: URL.createObjectURL(event.currentTarget.files[0]),
                           raw: event.currentTarget.files[0]
                         });
                         formik.setFieldValue("photo", event.currentTarget.files[0]);
                         formik.setFieldValue("photoURL", URL.createObjectURL(event.currentTarget.files[0]));
                         formik.handleChange(event)
                       }}
                />
              </Button>

              <FormLabel id="upload-group" sx={{fontSize: 17, fontWeight: 500, width: '100%'}}>
                <img src={formik.values.photoURL} className="preview-image"/>
              </FormLabel>

            </FormControl>
          </Box>


          <Box sx={{textAlign: 'center', width: '100%', mt: 6}}>
            <FormHelperText className="error-message" sx={{textAlign: 'center'}}>
              {(formik.touched.conceptName && formik.errors.conceptName)
              || (formik.touched.mealPart && formik.errors.mealPart)
              || (formik.touched.description && formik.errors.description)
              || (formik.touched.chef && formik.errors.chef)
              || (formik.touched.segment && formik.errors.segment)
              || (formik.touched.brand && formik.errors.brand)
              || (formik.touched.regionCountry && formik.errors.regionCountry)
              || (formik.touched.brandOtherText && formik.errors.brandOtherText)
              || (formik.touched.productCat1Description && formik.errors.productCat1Description)
              || (formik.touched.productCat1 && formik.errors.productCat1)
              || (formik.touched.productCat2Description && formik.errors.productCat2Description)
              || (formik.touched.productCat3Description && formik.errors.productCat3Description)
              || (formik.touched.productCat4Description && formik.errors.productCat4Description)
                ? 'Please enter all required fields.'
                : ' '}
            </FormHelperText>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              sx={{
                mt: 2,
                mb: 6,
                px: 6,

              }}
              disabled={savingConcept}
            >
              SAVE CONCEPT
            </Button>
          </Box>
          {!!snackbar && (
            <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
              <Alert {...snackbar} onClose={handleCloseSnackbar}/>
            </Snackbar>
          )}
        </form>

      </Box>

    </Container>
  );


}



